<template>
  <div class="vehicle-assess">
    <div class="page-content">
      <div v-for="(item, i) in enums" :key="i" class="list-item">
        <div class="item-tit">
          <span class="name">{{ item.desc }}</span>
          <span class="example" @click="previewDemo(item.value)">
            <van-icon name="eye-o" />
            示例
          </span>
        </div>
        <Upload
          v-model="item.imgs"
          previewVali="mobile"
          :maxCount="1"
          :isDefaultAccept="false"
          accept=".jpg"
          @upload="uploadFunction($event, item, i)"
          @del="deleteFile($event, item, i)"
        />
      </div>
    </div>

    <div class="page-btn btns">
      <van-button size="small" @click="$router.go(-1)">返回</van-button>
      <van-button type="info" size="small" @click="assessFn">提交</van-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ImagePreview } from "vant";
import { assessVehiclev2, assessEnums } from "@/api/apply";
export default {
  components: {
    Upload: () => import("@/components/Upload.vue")
  },
  computed: {},
  data() {
    return {
      bizNo: "",
      enums: [],
      // imgs: [],
      params: []
    };
  },
  mounted() {
    const data = this.getUserOrderInfo();
    this.bizNo = data.bizNo || "";
    this.getEnums();
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    uploadFunction(param, item, i) {
      const { url } = param;
      const { key } = item;
      this.$set(this.params, i, {
        partCode: key,
        imageUrl: url
      });
    },
    deleteFile(index, item, i) {
      // item.imgs[i].splice(index, 1);
      this.params.splice(i, 1);
    },
    async getEnums() {
      try {
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await assessEnums();
        this.enums = (data || []).map((e) => {
          return {
            ...e,
            imgs: []
          };
        });
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    previewDemo(url) {
      ImagePreview({ images: [url], closeable: true });
    },
    async assessFn() {
      try {
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const params = {
          images: this.params
        };
        await assessVehiclev2(this.bizNo, params);
        this.$toast.success("提交成功");
        this.$router.go(-1);
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vehicle-assess {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.page-content {
  flex: 1;
  overflow-y: auto;
  padding: 0 32px;
}
.list-item {
  .item-tit {
    display: flex;
    font-size: 32px;
    line-height: 88px;
    .name {
      color: #333;
    }
    .example {
      margin-left: 32px;
      font-size: 28px;
      color: #faad14;
    }
  }
}
.page-btn {
  height: 112px;
  min-height: 112px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #eee;
  box-shadow: 0 -10px 10px #f6f6f6;
  z-index: 10;
  .van-button {
    height: 100%;
    width: 45%;
  }
}
</style>
